@font-face {
    font-family: "KoulenRegular";
    src: local("Koulen-Regular"),
     url("Koulen-Regular.ttf") format("truetype");
    font-weight: bold;
    }
    
    .KoulenRegularFont {
        font-family: "KoulenRegular";
        font-size: 60px;;
       }
       